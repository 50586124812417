<template>
    <div style="text-align: center;margin-bottom: 20px;">
        <p class="ai_title">{{ ai_title }}</p>
    </div>
</template>

<script>
export default {
    name: '',
    props: {
        ai_title: String
    },
    data() {
        return {}
    },
}
</script>

<style lang=scss scoped>
.ai_title {
    margin: 0 auto;
    font-size:28px;
    font-weight: bold;
    background: linear-gradient(90deg, rgba(0, 130, 252, 1) 0%, rgba(237, 131, 107, 1) 100%);
    /* 渐变背景色 */
    -webkit-background-clip: text;
    /* 适用于 WebKit 浏览器 */
    -moz-background-clip: text;
    /* 适用于 Firefox 浏览器 */
    background-clip: text;
    /* 适用于大部分现代浏览器 */
    color: transparent;
    /* 隐藏文字颜色 */
    display: inline-block;
    /* 使背景裁剪到文字 */
}
</style>