<template>
    <div>
        <el-input resize="none" readonly type="textarea" :rows="13" v-model="contentData.output_content">
        </el-input>
        <button class="create_but" @click="copy">复制内容</button>
    </div>
</template>

<script>
export default {
    props: {
        contentData: Object
    },
    data() {
        return {}
    },
    methods: {
        copy() {
            //参数是复制的内容
            //.then()中的函数是复制成功和复制失败的两个回调函数
            this.$copyText(this.$props.contentData.output_content).then(
                (e) => {
                    return this.$message({
                        message: '复制成功',
                        type: 'success',
                        offset: 80
                    });
                },
                (err) => {
                    return this.$message({
                        message: '复制失败',
                        type: 'warning',
                        offset: 80
                    });
                }
            );
        },
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai'
</style>