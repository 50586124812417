<template>
    <div>
        <!-- <button @click="login_show_but">生成内容</button> -->
        <el-button class="create_but" type="primary" @click="login_show_but" :loading="loading">{{loading?'生成中...':'生成内容'}}</el-button>
    </div>
</template>

<script>
import { goAi } from '@/util/goAi'
import { mapState } from 'vuex';
export default {
    compute: {
        ...mapState(['loginShow'])
    },
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        loading_show(){
            this.loading = !this.loading
        },
        async login_show_but() {
            const aiStatus = await goAi()
            if (aiStatus == 'ok') {
                this.$emit('file_data')
            } else {
                return this.$store.state.loginShow = true
            }
        },
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai'
</style>