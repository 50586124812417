<template>
    <div class="switch_box flex">
        <div @click="select_versionShow('1')" :class="select_version == '1' ? 'sliderActive' : 'switchLt'">
            {{tab1_name}}
        </div>

        <div @click="select_versionShow('2')" :class="select_version == '2' ? 'sliderActive' : 'switchRt'">
            {{ tab2_name }}
        </div>
        <div :class="select_version == '1' ? 'slider1' : 'slider2'"></div>
    </div>
</template>

<script>
export default {
    props: {
        tab1_name:String,
        tab2_name:String,
        select_version: String
    },
    data() {
        return {}
    },
    methods: {
        select_versionShow(id) {
            if (this.select_version == id) {
                return
            }
           this.$emit('select_versionShow',id) 
        }
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai'
</style>