<template>
    <div>
        <Navigation />
        <div class="bg_box">
            <div class="noneBox"></div>
            <div class="box">
                <p class="back" @click="$router.push('/wordNav')">返回</p>

                <div class="ct_box flex">
                    <div class="form_upload_box">
                        <ai_switch :select_version="form.select_version" tab1_name="日常版" tab2_name="文学版"
                            @select_versionShow="select_versionShow" />
                        <ai_title ai_title="“一言一文”AI商评生成" />
                        <ai_select ref="select_type" :selectTitle="form.select_version=='1'?'商评类型':'评论类型'" :select_list="type_list" @getSelectVal="get_type" />
                        <div v-if="form.select_version == '1'">
                            
                            <div class="form_ct flex">
                                <p class="upLoad_text">商评主体：</p>
                                <div class="upload_box">
                                    <el-input v-model="form.evaluate_theme" placeholder="针对预评论的对象进行简要阐述。"></el-input>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="form_ct flex">
                                <p class="upLoad_text">评论主体：</p>
                                <div class="upload_box">
                                    <el-input v-model="form.evaluate_theme" placeholder="针对预评论的对象进行简要阐述。"></el-input>
                                </div>
                            </div>
                        </div>
                        <div class="form_ct flex">
                            <p class="upLoad_text">评论阐述：</p>
                            <div class="upload_box">
                                <el-input resize="none" show-word-limit type="textarea" :rows="3"
                                    v-model="form.evaluate_elaborate"
                                    placeholder="针对预评论的内容发表自己的观点和意见，并对自身观点进行简要描述或感官关键词录入">
                                </el-input>
                            </div>
                        </div>
                        <ai_button_file_data ref="ai_button_file_data" @file_data="file_data" />
                        <ai_text_content :contentData="contentData" />
                    </div>
                    <wordNav />
                    <aiNum title="计费规则：1000tokens = 0.9元，" text="1000tokens在700-750字左右" :visible="aiNumShow"
                        @close="aiNumShow = false" @postData="postData" />
                    <aiPoup :visible="aiPoupShow" @close="aiPoupShow = false" />
                </div>
            </div>
        </div>
        <footerBot />
    </div>
</template>

<script>
import wordNav from '@/components/wordNav.vue'
import ai_switch from '@/components/ai_switch'
import ai_select from '@/components/ai_select'
import ai_title from '@/components/ai_title'
import ai_text_content from '@/components/ai_text_content'
import ai_button_file_data from '@/components/ai_button_file_data'
export default {
    mounted() { this.getUserInfo() },
    components: {
        ai_title,
        wordNav,
        ai_switch,
        ai_select,
        ai_text_content,
        ai_button_file_data
    },
    data() {
        return {
            form: {
                select_version: '1',
                evaluate_style: '', //  # 商评类型
                evaluate_theme: '', //  # 商评主体
                evaluate_elaborate: '', //  # 商评阐述
            },
            type_list: ['政论', '网络新闻', '网络购物'],
            contentData: {}, //内容
            aiNumShow: false,
            aiPoupShow: false,

        }
    },
    methods: {
        select_versionShow(id) {
            this.form = {
                select_version: id,
                evaluate_style: '', //  # 商评类型
                evaluate_theme: '', //  # 商评主体
                evaluate_elaborate: '', //  # 商评阐述
            }
            this.$refs.select_type.selectClear()
            if (this.form.select_version == '1') {
                this.type_list = ['政论', '网络新闻', '网络购物']
            } else {
                this.type_list = ['诗歌', '小说', '散文', '戏剧', '影视']
            }

        },
        get_type(val) {
            this.form.evaluate_style = val
        },
        // 获取用户信息
        getUserInfo() {
            let $user_info = JSON.parse(localStorage.getItem('user_info'))
            if ($user_info) {
                this.curlGet('/api/user/info').then((res) => {
                    if (res.data.code) {
                        this.$user_info = res.data.data
                        this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
                    }
                })
            }
        },
        file_data() {
            // if (this.form.type == '') {
            //     return this.$message({
            //         message: '请输入从事行业',
            //         type: 'warning',
            //         offset: 80,
            //     });
            // }
            // if (this.form.business == '') {
            //     return this.$message({
            //         message: '请输入标题',
            //         type: 'warning',
            //         offset: 80,
            //     });
            // }
            // if (this.form.keyword == '') {
            //     return this.$message({
            //         message: '请输入关键字',
            //         type: 'warning',
            //         offset: 80,
            //     });
            // }
            if (this.$user_info.money <= 0) {
                this.aiPoupShow = true
            } else {
                this.aiNumShow = true
            }

        },
        postData() {
            this.aiNumShow = false
            this.$refs.ai_button_file_data.loading_show()
            let data = {}
            data.mobile = this.$user_info.mobile
            data.select_version = this.form.select_version
            data.evaluate_style = this.form.evaluate_style
            data.evaluate_theme = this.form.evaluate_theme
            data.evaluate_elaborate = this.form.evaluate_elaborate
            this.curlPost('/file/create_evaluate', data).then(res => {
                this.$refs.ai_button_file_data.loading_show()
                if (res.data.code) {
                    this.contentData = res.data.data
                    // 扣费
                    this.curlPost('/api/users/money_log/spend', {
                        ai_id: res.data.data.id,
                        amount: res.data.data.money,
                    }).then(res => {
                        if (res.data.code) {
                            this.getUserInfo()

                        }
                    })
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        }
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai'
</style>